<template>
  <div class="close"></div>
</template>
<style lang="scss" scoped>
.close {
  width: 100%;
  height: 100%;
  background-color: #000;
}
</style>
